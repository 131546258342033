import React, { Component } from "react";

export default class Button extends Component {
  getIcon = () => {
    if (this.props.type === "messenger") {
      return "fab fa-facebook-messenger";
    } else if (this.props.type === "newsletter") {
      return "fas fa-envelope";
    } else if(this.props.type === "requestevent") {
      return "far fa-edit";
    }
    else{
      return "fas fa-plus"
    }
  };

  getLink = () => {
    if (this.props.type === "messenger") {
      return "https://m.me/bayarea.muslim.events";
    } else if (this.props.type === "newsletter") {
      return "https://airtable.com/shrnY3xWlZIDxrSFH";
    } else if(this.props.type === "requestevent") {
      return "https://airtable.com/shr1cPsqRM4gXc8kh";
    }
    else{
      return "https://airtable.com/shrTIa950Ty01DLQa"
    }
  }
  render() {
    return (
      <a className={"button " + this.props.type} href={this.getLink()} target="_blank">
        <i
          className={this.getIcon()}
          style={{ fontSize: 23, marginRight: 11, verticalAlign: "middle"}}
        />
        <span style={{textDecoration: "none"}}>{this.props.title}</span>
      </a>
    );
  }
}
