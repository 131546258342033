import React, { Component } from "react";
import request from "superagent";

export default class SubmitURL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      placeholderText: "Facebook event URL",
      confirmationMessage: "",
      isLoading: false
    };
  }

  getInputWidth = () => {
    if (window.innerWidth >= 425) {
      return 300;
    } else {
      return window.innerWidth - 130;
    }
  };

  handleURLChange = event => {
    this.setState({ url: event.target.value, confirmationMessage: "" });
  };

  submitURL = () => {
    let eventURL = this.state.url;

    this.setState({
      isLoading: true,
      url: "",
      placeholderText: "Submit another url here"
    });

    request
      .get(
        `https://admins.muslim.events/api/scrape?url=${eventURL}`
      )
      .set("Accept", "application/json")
      .end((err, res) => {
        this.setState({
          isLoading: false
        });
        if (res && !res.badRequest) {
          if (res.body && res.body.success === "OK") {
            this.setState({
              confirmationMessage:
                "🎉🎉🎉! Your request has been received and the event will be live on the calendar in the next 5 mins. If you do not see the event, please contact us at support@muslim.events"
            });
          } else if (res.body && res.body.error) {
            this.setState({
              confirmationMessage:
                "🐛 An error has occured. Try again in 15 mins and make sure that the url is correct. If the problem persists, please contact us or add it on this form: https://airtable.com/shrTIa950Ty01DLQa \n" +
                res.body.error
            });
            console.log(res.body.error);
          } else {
            this.setState({
              confirmationMessage:
                "🐛 An error has occured. Try again in 15 mins and make sure that the url is correct. If the problem persists, please contact us or add it on this form: https://airtable.com/shrTIa950Ty01DLQa"
            });
            console.log(err);
          }
        }
      });
  };

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        <a
          className={"button " + this.props.type}
          target="_blank"
          style={{ background: "white" }}
        >
          <span>
            <input
              style={{ width: this.getInputWidth(), color: "#383E50" }}
              type="text"
              name="fname"
              placeholder={this.state.placeholderText}
              value={this.state.url}
              onChange={this.handleURLChange}
            />
          </span>
        </a>
        {isLoading ? (
          <span className="submiteventsubmit">
            <span className="loader" />
          </span>
        ) : (
          <span className="submiteventsubmit" onClick={this.submitURL}>
            submit
          </span>
        )}
        <div style={{ marginTop: 10, fontSize: 16 }}>
          <a
            href="https://airtable.com/shrTIa950Ty01DLQa"
            target="_blank"
            style={{ color: "#9F9F9F", textDecoration: "underline" }}
          >
            or enter it manually
          </a>
        </div>
        <div style={{ maxWidth: 300, margin: "0 auto", marginTop: 30 }}>
          {this.state.confirmationMessage}
        </div>
      </div>
    );
  }
}
