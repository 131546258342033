import React, { Component } from "react";

export default class AddToCalendar extends Component {
  getContainerWidth() {
    return window.innerWidth < 1140 ? window.innerWidth - 30 : 1140;
  }
  render() {
    if (window.innerWidth >= 425) {
      return (
        <div
          style={{
            width: this.getContainerWidth(),
            textAlign: "right",
            margin: "0 auto",
            marginTop: 10
          }}
        >
          <a
            style={{ fontSize: "110%", paddingRight: 5, color: "inherit" }}
            href="https://calendar.google.com/calendar/b/2?cid=MWk4YXFyNnU1MTI0aDVsZXRtOGptNDFubzBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ"
          >
            view Google Calendar
          </a>
        </div>
      );
    } else {
      return null;
    }
  }
}
